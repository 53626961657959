import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';

const CustomLink = loadable(() => import('../common/CustomLink'));
const ReactSlick = loadable(() => import('../common/ReactSlick'));
import { loaded } from './../../utils/helpers';

const ShopByCategory = React.memo(({ title, data }) => {
  const box = useRef(null);
  const len = data.length;

  const settings = {
    dots: false,
    slidesToShow: 5,
    infinite: len > 5,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          infinite: len > 4,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          infinite: len > 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          infinite: len > 1,
          centerMode: true,
        },
      },
    ],
    beforeChange: (prev, next) => {
      // console.log({ prev });
      // console.log({ next });
    },
  };

  function handleSlickGoTo(next = true) {
    // console.log(box);
    if (box.current) {
      next ? box.current.slickNext() : box.current.slickPrev();
    }
  }

  const checkShowArrows = () => {
    if (loaded) {
      const width = window?.innerWidth;
      const len = data?.length;
      if (width < 768 && len >= 2) return true;
      else if (width < 992 && len > 3) return true;
      else if (width < 1200 && len > 4) return true;
      else if (len > 5) return true;
      else return false;
    }
    return false;
  };

  return (
    <div className="main__shop-by-category container-xl">
      <div className="main__shop-by-category__title -title --arrows">
        <h2>{title}</h2>

        {checkShowArrows() && (
          <div className="arrows">
            <span className="arrows__btn --left" onClick={() => handleSlickGoTo(false)}></span>
            <span className="arrows__btn --right" onClick={() => handleSlickGoTo()}></span>
          </div>
        )}
      </div>

      <ReactSlick ref={box} {...settings} className="main__shop-by-category__owl-carousel">
        {data.map((v, i) => (
          <div key={i} className="item">
            <CustomLink
              link={v.url_link}
              text={
                <GatsbyImage image={v.image.gatsbyImageData} alt={v.image.alt || 'activeskin'} />
              }
            />
            <div className="item__content">
              <h3>{v.title}</h3>
              <CustomLink link={v.url_link} text={v.url_text} />
            </div>
          </div>
        ))}
      </ReactSlick>
    </div>
  );
});

export default ShopByCategory;
